import React, {useContext} from "react"
import LoginLayout from "../components/layouts/loginLayout"
import Card from "react-bootstrap/Card";
import {Col, Form, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AppAlert from "../components/appAlert";
import alertService from "../services/alert";
import {Alert, AlertType} from "../models/alert";
import userService from "../services/user"
import * as messages from "../components/ui_messages";
import * as EmailValidator from 'email-validator';
import UsernameInput from "../components/usernameInput";
import {observable} from "mobx";
import {Link} from "gatsby";
import {GlobalStateContext} from "../context/GlobalContextProvider";
import PasswordInputConfirm from "../components/passwordConfirmationInput";
import withLocation from "../hoc/withLocation";
import * as queryString from "querystring";

const ResetPasswordPage = ({location, data}) => {
    const isBrowser = () => typeof window !== "undefined"


    const emailAddress = observable({val:""});
    const password = observable({val:""});
    const password_confirm = observable({val:""});
    const state = useContext(GlobalStateContext);

    let parsed;
    if(isBrowser()) {
        parsed = queryString.parse(location.search);
        console.log( queryString.parse(location.search));
        console.log(process.env);

    }

    async function handleSubmit(event) {
        event.preventDefault();

        const validationMessage = ValidateForm();

        if (validationMessage.length > 0)
        {
            alertService.clear('forgotPassword');
            alertService.push(new Alert(validationMessage, 'forgotPassword', AlertType.Error))
            return;
        }

        userService.resetPassword(password.val, parsed["?client_id"], parsed.expiry, parsed.token, parsed.uid);
    }

    function ValidateForm()
    {
        if (!password.val || !password_confirm.val)
        {
            return messages.PASSWORD_REQUIRED;
        }

        if (password.val != password_confirm.val)
        {
            return messages.PASSWORD_CONFIRMATION;
        }

        return "";
    }

    const registerButton = (state.mobile)
        ? null
        : <Col>
            <Link to="/register">Register</Link>
        </Col>;
    return (
        <LoginLayout title="Register">
            <Card bg='light' className="p-2 mb-5 container">
                <Card.Body className="pt-3 pb-2">
                    <h1>Password Reset Form</h1>
                    <Form>
                        <PasswordInputConfirm name="password" value={password} placeholder={"Password"}/>
                        <PasswordInputConfirm name="password_confirm" value={password_confirm} placeholder={"Confirm Password"} />

                    </Form>
                    <p>
                        <Button variant="primary" onClick={handleSubmit}>Request Reset</Button>
                    </p>
                    <Row style={{ paddingTop: 30 }}>
                        <Col>
                            <Link to="/login">Login</Link>
                        </Col>
                        {registerButton}
                    </Row>
                    <AppAlert/>

                </Card.Body>
            </Card>

        </LoginLayout>
    )
}

export default ResetPasswordPage