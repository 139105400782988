import React from "react";
import {Form, InputGroup} from "react-bootstrap";
import {MdVisibility, MdVisibilityOff} from "react-icons/all";
import {observer, useLocalStore} from "mobx-react";

const PasswordInputConfirm = observer((props) => {

    const localState = useLocalStore(() => ({
        passwordIsVisible: false,
    }))

    function onChange(event) {
        props.value['val'] = event.target.value;
    }

    function toggleShow(event) {
        event.preventDefault();
        localState.passwordIsVisible = ! localState.passwordIsVisible;
    }

    return <Form.Group controlId="formBasicPassword">
        <InputGroup>
            <Form.Control type={localState.passwordIsVisible? "text" : "password" } placeholder={props.placeholder}
                          name={props.name} value={props.value.val} onChange={onChange} />
            <InputGroup.Append>
                <InputGroup.Text>
                    { localState.passwordIsVisible? (<MdVisibility onClick={toggleShow} />) : (<MdVisibilityOff onClick={toggleShow} /> ) }
                </InputGroup.Text>
            </InputGroup.Append>
        </InputGroup>
    </Form.Group>
})

export default PasswordInputConfirm